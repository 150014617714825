<template>
  <div>
    <b-form-group :label="$t('system.broadcast_domain') + ': ' + port_name"
                  label-for="bcd-select">
      <Typeahead :data="bcds" v-model="bcd_search"
                 @select="updateValue($event)"
                 @input="selected_bcd=null"
                 :placeholder="$t('system.broadcast_domain') + ': ' + port_name"
                 :serializer="serialize_bcds"
                 :min-matching-chars="-1"
                 :max-matches="-1"
                 :loading="false/* loading > 0 */"
                 compact
                 :disabled="!bcds || bcds.length === 0 || loading_edit_action"
                 ref="bcdSelect"
                 class="mb-3" :id="'bcd-select-' + port_name"/>
    </b-form-group>
    <b-form-group :description="$t('components.patch_request_form.tagged_hint')">
      <b-checkbox switch v-model="bcd_tagged" @change="updateValue(null)"
                  class="mb-3" :id="'bcd-tagged-' + port_name">{{
          $t('components.patch_request_form.tagged')
        }}
      </b-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import Typeahead from '@/components/Typeahead'

export default {
  name: 'PatchRequestBCDPicker',
  components: { Typeahead },
  data() {
    return {
      bcd_search: '',
      selected_bcd: '',
      bcd_tagged: false,
    }
  },
  mounted() {
    if (!this.value) {
      return
    }
    this.selected_bcd = this.value.bcd
    this.bcd_search = this.selected_bcd.name
    this.bcd_tagged = this.value.is_tagged
  },
  props: {
    value: {
      // {is_tagged: Boolean, bcd: {gpk, name, ...}}
      type: Object,
      required: true
    },
    port_name: {
      type: String,
      required: true
    },
    bcds: {
      type: Array,
      required: true
    },
    loading_edit_action: {
      type: Boolean,
      required: true
    },
    serialize_bcds: {
      type: Function,
      required: true
    },
  },
  methods: {
    updateValue(event) {
      if (event !== null) {
        this.selected_bcd = event
      }
      if (this.selected_bcd === '') {
        this.selected_bcd = null
      }
      this.$emit('input', {is_tagged: this.bcd_tagged, bcd: this.selected_bcd})
    }
  },
}
</script>

<style scoped>

</style>
