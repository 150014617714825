<template>
  <b-modal id="informal-patch-request-form" @shown="update" @hidden="clear" @ok="handleSubmit" @cancel="handleCancel"
           size="lg">
    <template #modal-title>
      {{ $t('components.patch_request_form.informal_form_title') }}
    </template>
    <b-form @submit.stop.prevent="handleSubmit">
      <b-form-group :label="$t('components.patch_request_form.site')" label-for="site-select">
        <Typeahead :data="sites" v-model="site_search"
                   @select="selected_site=$event;focusComment()"
                   @input="selected_site=null"
                   :placeholder="$t('components.patch_request_form.site')"
                   :serializer="serialize_sites"
                   :min-matching-chars="-1"
                   :max-matches="-1"
                   :disabled="(sites && sites.length === 0)"
                   compact
                   ref="siteSelect"
                   class="mb-3" id="site-select"/>
      </b-form-group>
      <b-form-group :label="$t('components.patch_request_form.comment')" label-for="comment-input">
        <b-form-textarea
            ref="commentField"
            :placeholder="$t('components.patch_request_form.informal_comment_placeholder')"
            v-model="comment"
            rows="5"
            class="mb-3" id="comment-input"></b-form-textarea>
      </b-form-group>
      <b-form-group :label="$t('components.patch_request_form.reply_to')" label-for="reply-to-input">
        <b-form-input
            :placeholder="$t('components.patch_request_form.optional_reply_to')"
            v-model="reply_to"
            class="mb-3" id="reply-to-input"></b-form-input>
      </b-form-group>
    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button variant="secondary" @click="cancel()">
        {{ $t('components.patch_request_form.cancel') }}
      </b-button>
      <b-button variant="primary" :disabled="!can_send" @click="ok()">
        {{ $t('components.patch_request_form.send') }}
        <b-spinner small v-show="sending"/>
        <netvs-icon v-show="status_icon !== null" :icon="status_icon"></netvs-icon>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import PatchRequestService from '@/api-services/patch_request.service'
import Typeahead from '@/components/Typeahead'

export default {
  name: 'InformalPatchRequestForm',
  components: {Typeahead},
  data() {
    return {
      loading: 0,
      sites: null,
      site_search: '',
      selected_site: null,
      serialize_sites: site => site.description ? `${site.fq_name} (${site.description})` : site.fq_name,
      comment: '',
      reply_to: '',
      sending: false,
      status_icon: null,
      error_message: null
    }
  },
  methods: {
    async update() {
      this.clear()
      this.loading++
      const response = await PatchRequestService.list_sites(this.$store.state)
      this.sites = response.data
      if (this.sites.length === 1) {
        this.selected_site = this.sites[0]
        this.site_search = this.serialize_sites(this.selected_site)
      } else if (this.$refs.siteSelect) {
        this.$refs.siteSelect.focus()
      }
      this.loading--
    },
    focusComment() {
      this.$nextTick(() => {
        if (this.$refs.commentField) this.$refs.commentField.focus()
      })
    },
    clear() {
      this.sites = null
      this.selected_site = null
      this.site_search = ''
      this.comment = ''
    },
    handleSubmit(e) {
      e.preventDefault() // Prevent closing of modal
      if (this.can_send) {
        this.sendPatchRequest()
      }
    },
    handleCancel() {
      this.$nextTick(() => {
        this.$bvModal.show('patch-action-form')
      })
    },
    sendPatchRequest() {
      this.status_icon = null
      this.sending = true
      this.error_message = null
      PatchRequestService.send_informal_patch_request(this.$store.state, {
        site: this.selected_site,
        comment: this.comment,
        reply_to: this.reply_to
      }).then(response => {
        this.sending = false
        this.status_icon = 'request_sent'
        this.clear()
        // close modal after 1 second
        setTimeout(() => {
          this.$bvModal.hide('patch-action-form')
          this.$bvModal.hide('informal-patch-request-form')
        }, 1000)
      }).catch(error => {
        window.console.log(error)
        this.sending = false
        this.status_icon = 'transaction_error'
        if (error.response.status === 400) {
          this.error_message = error.response.data.detail
        }
      })
    },
  },
  computed: {
    can_send() {
      return this.selected_site !== null && this.comment.length > 0
    }
  }
}
</script>

<style scoped>

</style>
