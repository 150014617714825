<template>
  <div>
    <b-modal id="patch-action-form" @shown="update" @hidden="clear" @ok="handleSubmit" size="lg">
      <template #modal-title>
        {{ $t('components.patch_request_form.action_title') }}
      </template>
      <b-overlay :show="loading_edit_action" rounded="sm">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-form-group :label="$t('components.patch_request_form.building')" label-for="building-select">
            <Typeahead :data="buildings" v-model="building_search"
                       @select="selected_building=$event;$nextTick(updateRooms)"
                       @input="selected_building=null;updateRooms()"
                       :placeholder="$t('components.patch_request_form.building')"
                       :serializer="serialize_buildings"
                       :min-matching-chars="-1"
                       :max-matches="-1"
                       :disabled="(buildings && buildings.length === 0) || loading_edit_action"
                       :loading="loading && !(buildings && buildings.length > 0)"
                       compact
                       ref="buildingSelect"
                       class="mb-3" id="building-select"/>
          </b-form-group>
          <b-form-group :label="$t('components.patch_request_form.room')" label-for="room-select">
            <Typeahead :data="rooms" v-model="room_search" @select="selected_room=$event;$nextTick(updateModules)"
                       @input="selected_room=null;updateModules()"
                       :placeholder="selected_building ? (rooms && rooms.length === 0) ? $t('components.patch_request_form.no_rooms') : $t('components.patch_request_form.room') : $t('components.patch_request_form.select_building_first')"
                       :serializer="serialize_rooms"
                       :min-matching-chars="-1"
                       :max-matches="-1"
                       :disabled="!selected_building || (rooms && rooms.length === 0) || loading_edit_action"
                       :loading="loading && selected_building && !(rooms && rooms.length > 0)"
                       compact
                       ref="roomSelect"
                       class="mb-3" id="room-select"/>
          </b-form-group>
          <b-form-group :label="$t('components.patch_request_form.module')" label-for="module-select">
            <Typeahead :data="modules" v-model="module_search"
                       @select="selected_module=$event;$nextTick(updateActions)"
                       @input="selected_module=null;clearPPort(),updateActions()"
                       :placeholder="selected_room ? (modules && modules.length === 0) ? $t('components.patch_request_form.no_modules') : $t('components.patch_request_form.module') : $t('components.patch_request_form.select_room_first')"
                       :serializer="serialize_modules"
                       :min-matching-chars="-1"
                       :max-matches="-1"
                       :disabled="!selected_room || (modules && modules.length === 0)"
                       :loading="loading && selected_room && !(modules && modules.length > 0)"
                       compact
                       ref="moduleSelect"
                       class="mb-3" id="module-select"/>
          </b-form-group>
          <b-form-group :label="$t('components.patch_request_form.action')" label-for="action-select">
            <b-form-select @change="selected_action=$event;clearInserts();$nextTick(updatePPorts);"
                           :value="selected_action"
                           id="action-select"
                           :disabled="!selected_module || loading_edit_action"
                           ref="actionSelect">
              <b-form-select-option disabled :value="null">{{ $t('system.please_select') }}</b-form-select-option>
              <b-form-select-option value="patch">{{
                  $t('components.patch_request_form.actions.patch')
                }}
              </b-form-select-option>
              <b-form-select-option value="unpatch">
                {{
                  $t('components.patch_request_form.actions.unpatch')
                }}
              </b-form-select-option>
              <b-form-select-option value="change_insert"
                                    v-if="insert_changeable_modules.some(m => selected_module && m.gpk === selected_module.gpk)">
                {{
                  $t('components.patch_request_form.actions.change_insert')
                }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group class="mt-3" :label="$t('components.patch_request_form.p_port')" label-for="p-port-select"
                        v-if="['patch', 'unpatch'].includes(selected_action)">
            <Typeahead :data="matching_p_ports" v-model="p_port_search"
                       @select="selected_p_port=$event;$nextTick(updateBCDs)"
                       @input="selected_p_port=null"
                       :placeholder="matching_p_ports.length === 0 ? $t('components.patch_request_form.no_p_ports') : $t('components.patch_request_form.p_port')"
                       :serializer="serialize_p_ports"
                       :min-matching-chars="-1"
                       :max-matches="-1"
                       :loading="loading && selected_action && !(matching_p_ports && matching_p_ports.length > 0)"
                       compact
                       :disabled="matching_p_ports.length <= 1 || loading_edit_action"
                       ref="pPortSelect"
                       :sort="false"
                       class="mb-3" id="p-port-select"/>
          </b-form-group>
          <!-- BCD -->
          <b-form-group
              :label="selected_action === 'patch'?$t('system.broadcast_domain'):$t('components.patch_request_form.bcd_optional')"
              label-for="bcd-select"
              v-if="['patch', 'unpatch'].includes(selected_action)">
            <Typeahead :data="bcds" v-model="bcd_search" @select="selected_bcd=$event"
                       @input="selected_bcd=null"
                       :placeholder="$t('system.broadcast_domain')"
                       :serializer="serialize_bcds"
                       :min-matching-chars="-1"
                       :max-matches="-1"
                       :loading="loading > 0"
                       compact
                       :disabled="!bcds || bcds.length === 0 || loading_edit_action"
                       ref="bcdSelect"
                       class="mb-3" id="bcd-select"/>
          </b-form-group>
          <b-form-group :description="$t('components.patch_request_form.tagged_hint')"
                        v-if="['patch'].includes(selected_action)">
            <b-checkbox switch v-if="['patch'].includes(selected_action)" v-model="add_tagged"
                        class="mb-3" id="bcd-tagged">{{ $t('components.patch_request_form.tagged') }}
            </b-checkbox>
          </b-form-group>
          <!-- INSERT TYPES -->
          <template v-if="['change_insert'].includes(selected_action)">
            <b-form-group label-for="insert_select"
                          :label="$t('components.patch_request_form.insert')">
              <b-form-select :value="selected_insert_type" @change="handleInsertChange"
                             :options="insert_type_options" id="insert_select">
              </b-form-select>
            </b-form-group>
            <template v-for="port in bcd_assignable_insert_ports">
              <PatchRequestBCDPicker
                  :bcds="bcds"
                  :loading_edit_action="loading_edit_action"
                  :serialize_bcds="serialize_bcds"
                  :port_name="predict_port_name(port)"
                  :key="port.name"
                  v-model="selected_insert_bcds[predict_port_name(port)]"
              ></PatchRequestBCDPicker>
            </template>
          </template>
          <!-- COMMENT -->
          <b-form-group :label="$t('components.patch_request_form.comment')" label-for="comment-input">
            <b-form-textarea
                :placeholder="$t('components.patch_request_form.comment_placeholder')"
                :disabled="loading_edit_action"
                v-model="comment"
                rows="5"
                class="mb-3" id="comment-input"></b-form-textarea>
          </b-form-group>
        </b-form>
      </b-overlay>
      <template #modal-footer="{ ok, cancel }">
        <div class="flex-grow-1">
          <b-button variant="outline-secondary" @click="showInformalForm">
            {{ $t('components.patch_request_form.informal_request_hint') }}
          </b-button>
        </div>
        <b-button variant="secondary" @click="cancel()">
          {{ $t('components.patch_request_form.close') }}
        </b-button>
        <b-button variant="primary" :disabled="!can_apply" @click="ok()">
          <template v-if="$store.state.patch_request_edit_action">
            <netvs-icon icon="edit"/>
            {{ $t('components.patch_request_form.apply_edit') }}
          </template>
          <template v-else>
            <netvs-icon icon="add_item"/>
            {{ $t('components.patch_request_form.apply') }}
          </template>
          <netvs-icon v-show="show_added" icon="check"/>
        </b-button>
      </template>
    </b-modal>
    <InformalPatchRequestForm/>
  </div>
</template>

<script>
import BuildingService from '@/api-services.gen/nd.bldg'
import PatchRequestService from '@/api-services/patch_request.service'
import Typeahead from '@/components/Typeahead'
import Apiutil from '@/util/apiutil'
import InformalPatchRequestForm from '@/components/InformalPatchRequestForm.vue'
import PatchRequestBCDPicker from '@/components/PatchRequestBCDPicker.vue'

export default {
  name: 'PatchActionForm',
  components: {PatchRequestBCDPicker, InformalPatchRequestForm, Typeahead},
  data() {
    return {
      loading: 0,
      buildings: null,
      building_search: '',
      selected_building: null,
      serialize_buildings: building => building.name ? `${building.number} (${building.name}) [${building.site_fq_name}]` : `${building.number} [${building.site_fq_name}]`,
      rooms: null,
      room_search: '',
      selected_room: null,
      serialize_rooms: room => room.name ? `${room.number} (${room.name})` : room.number,
      modules: null,
      insert_changeable_modules: [],
      module_search: '',
      selected_module: null,
      serialize_modules: module => module.type ? `${module.fq_name} (${module.type})` : module.fq_name,
      action_search: '',
      selected_action: null,
      p_ports: null,
      p_port_search: '',
      selected_p_port: null,
      serialize_p_ports: p_port => !p_port.is_edge_node ? `${p_port.name} (${this.$t('components.patch_request_form.p_port_not_edge')})` : p_port.name,
      bcds: null,
      bcd_search: '',
      selected_bcd: null,
      add_tagged: false,
      serialize_bcds: bcd => bcd.name,
      comment: '',
      loading_edit_action: false,
      show_added: false,
      insert_types: {},
      selected_insert_type: null,
      selected_insert_bcds: {},
    }
  },
  methods: {
    predict_port_name(port) {
      return port.name.replace('%{module_name}', this.selected_module ? this.selected_module.fq_name : '')
    },
    async update() {
      this.comment = ''
      if (this.$store.state.patch_request_edit_action) this.loading_edit_action = true
      await this.updateBuildings()
      // TODO
      const insert_load = this.updateInsertTypes()
      const bcd_load = this.updateBCDs()
      if (this.$store.state.patch_request_edit_action) {
        this.loading_edit_action = true
        this.selected_building = this.$store.state.patch_request_edit_action.building
        this.building_search = this.serialize_buildings(this.selected_building)
        await this.updateRooms()
        this.selected_room = this.$store.state.patch_request_edit_action.room
        this.room_search = this.serialize_rooms(this.selected_room)
        await this.updateModules()
        this.selected_module = this.$store.state.patch_request_edit_action.module
        this.module_search = this.serialize_modules(this.selected_module)
        this.selected_action = this.$store.state.patch_request_edit_action.action
        if (this.selected_action === 'change_insert') {
          await insert_load
          this.selected_insert_type = this.$store.state.patch_request_edit_action.selected_insert_type
          this.selected_insert_bcds = this.$store.state.patch_request_edit_action.selected_insert_bcds
        } else {
          await this.updatePPorts()
          this.selected_p_port = this.$store.state.patch_request_edit_action.p_port
          this.p_port_search = this.$store.state.patch_request_edit_action.bcd ? this.serialize_p_ports(this.selected_p_port) : null
        }
        this.comment = this.$store.state.patch_request_edit_action.comment
        await bcd_load
        this.selected_bcd = this.$store.state.patch_request_edit_action.bcd
        this.bcd_search = this.selected_bcd ? this.selected_bcd.name : null
        window.console.debug(this.selected_insert_bcds)
        this.selected_action = this.$store.state.patch_request_edit_action.action
      }
      this.loading_edit_action = false
    },
    async updateInsertTypes() {
      this.clearAction()
      const response = await PatchRequestService.list_insert_types(this.$store.state)
      this.insert_types = Apiutil.dict_by_value_of_array(response.data, 'name')
    },
    async updateBuildings() {
      this.clearBuilding()
      this.loading++
      const response = await BuildingService.list(this.$store.state, {})
      this.buildings = response.data[0]
      if (this.buildings.length === 1) {
        this.selected_building = this.buildings[0]
        this.building_search = this.serialize_buildings(this.selected_building)
        await this.updateRooms()
      } else if (!this.$store.state.patch_request_edit_action) {
        if (!this.loading_edit_action && this.$refs.buildingSelect) this.$refs.buildingSelect.focus()
      }
      this.loading--
    },
    async updateRooms() {
      this.clearRoom()
      if (!this.selected_building) return
      this.loading++
      const response = await PatchRequestService.list_rooms(this.$store.state, this.selected_building.number)
      const floors_by_name = Apiutil.dict_of_lists_by_value_of_array(response.data.floor_list, 'name')
      this.rooms = response.data.room_list.sort((a, b) => {
        const floor_a = floors_by_name[a.floor][0]
        const floor_b = floors_by_name[b.floor][0]
        if (floor_a.name_sortby === floor_b.name_sortby) {
          return a.number.localeCompare(b.number)
        } else {
          return floor_a.name_sortby - floor_b.name_sortby
        }
      })
      if (this.rooms.length === 1) {
        this.selected_room = this.rooms[0]
        this.room_search = this.serialize_rooms(this.selected_room)
        await this.updateModules()
      } else if (!this.$store.state.patch_request_edit_action) {
        if (!this.loading_edit_action && this.$refs.roomSelect) this.$refs.roomSelect.focus()
      }
      this.loading--
    },
    async updateModules() {
      this.clearModule()
      if (!this.selected_building || !this.selected_room) return
      this.loading++
      const response = await PatchRequestService.list_modules(this.$store.state, this.selected_building.number, this.selected_room.number)
      this.modules = response.data.module_list
      this.insert_changeable_modules = response.data.insert_changeable_modules
      if (this.modules.length === 1) {
        this.selected_module = this.modules[0]
        this.module_search = this.serialize_modules(this.modules[0])
      } else if (!this.$store.state.patch_request_edit_action) {
        if (!this.loading_edit_action && this.$refs.moduleSelect) this.$refs.moduleSelect.focus()
      }
      const p_port_types = Apiutil.dict_by_value_of_array(response.data.p_port_type_list, 'name')
      this.p_ports = response.data.p_port_list
      this.p_ports = this.p_ports.filter(p_port => !p_port_types[p_port.type].is_internal)
      this.loading--
    },
    updateActions() {
      setTimeout(() => this.$refs.actionSelect.focus(), 15)
      if (['patch', 'unpatch'].includes(this.selected_action)) {
        this.updatePPorts()
      }
    },
    updatePPorts() {
      // pPorts pulled with modules, matching pPorts = computed, no update necessary
      if (this.matching_p_ports.length === 1) {
        this.selected_p_port = this.matching_p_ports[0]
        this.p_port_search = this.serialize_p_ports(this.selected_p_port)
        this.$nextTick(this.updateBCDs)
      } else if (!this.$store.state.patch_request_edit_action) {
        setTimeout(() => {
          if (!this.loading_edit_action && this.$refs.pPortSelect) this.$refs.pPortSelect.focus()
        }, 15)
      }
    },
    async updateBCDs() {
      this.clearBCD()
      this.loading++
      const response = await PatchRequestService.list_bcds(this.$store.state)
      this.bcds = response.data.bcd_list
      if (this.bcds.length === 1) {
        this.selected_bcd = this.bcds[0]
        this.bcd_search = this.serialize_bcds(this.selected_bcd)
        this.insert_bcd_search = {}
        for (const insert_name of this.selected_insert_bcds) {
          this.insert_bcd_search[insert_name] = this.serialize_bcds(this.selected_insert_bcds[insert_name])
        }
      } else if (!this.$store.state.patch_request_edit_action) {
        setTimeout(() => {
          if (!this.loading_edit_action && this.$refs.bcdSelect) this.$refs.bcdSelect.focus()
        }, 15)
      }
      this.loading--
    },
    clear() {
      this.clearBuilding()
      this.clearInserts()
      this.comment = ''
    },
    clearBuilding() {
      this.buildings = null
      this.selected_building = null
      this.building_search = ''
      this.clearRoom()
    },
    clearRoom() {
      this.rooms = null
      this.selected_room = null
      this.room_search = ''
      this.clearModule()
    },
    clearModule() {
      this.modules = null
      this.selected_module = null
      this.module_search = ''
    },
    clearPPort() {
      this.selected_p_port = null
      this.p_port_search = ''
    },
    clearInserts() {
      this.selected_insert_type = null
      this.selected_insert_bcds = {}
    },
    clearBCD() {
      this.selected_bcd = null
      this.bcd_search = ''
    },
    clearAction() {
      this.selected_action = null
    },
    handleInsertChange(ev) {
      if (this.selected_insert_type === ev) {
        return
      }
      this.selected_insert_type = ev
      this.selected_insert_bcds = {}
    },
    submitAction() {
      this.$store.commit('setPatchRequestEditAction', {
        action: {
          building: this.selected_building,
          room: this.selected_room,
          module: this.selected_module,
          action: this.selected_action,
          p_port: this.selected_p_port,
          bcd: this.selected_bcd,
          bcd_tagged: this.add_tagged,
          comment: this.comment,
          selected_insert_type: this.selected_insert_type,
          selected_insert_bcds: this.selected_insert_bcds,
        }
      })
      this.$emit('ok')
    },
    handleSubmit(e) {
      e.preventDefault() // Prevent closing of modal
      if (this.can_apply) {
        const edit = this.$store.state.patch_request_edit_action !== null
        this.submitAction()
        if (edit) {
          this.$nextTick(() => {
            this.$bvModal.hide('patch-action-form')
          })
        } else {
          this.show_added = true
          setTimeout(() => {
            this.show_added = false
          }, 2000)
        }
      }
    },
    showInformalForm() {
      this.$bvModal.show('informal-patch-request-form')
    }
  },
  computed: {
    matching_p_ports() {
      if (!this.selected_module) return []
      return this.p_ports.filter(p_port => p_port.mdl_fq_name === this.selected_module.fq_name)
        .sort((a, b) => a.name_sortby.localeCompare(b.name_sortby))
    },
    can_apply() {
      return !this.loading_edit_action &&
          this.selected_building &&
          this.selected_room &&
          this.selected_module &&
          this.selected_action &&
          (this.selected_p_port || (['change_insert'].includes(this.selected_action) && this.selected_insert_type !== null))
    },
    insert_type_options() {
      return Object.keys(this.insert_types).map(name => {
        return {value: name, text: this.insert_types[name].name}
      })
    },
    bcd_assignable_insert_ports() {
      console.log(this.selected_insert_type)
      if (!this.selected_insert_type) return []
      return this.insert_types[this.selected_insert_type].ports.filter(port => port.can_assign_bcd === true)
    }
  }
}
</script>

<style scoped>

</style>
