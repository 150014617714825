<template>
  <b-modal id="patch-request-form" @show="status_icon = null" @ok="sendPatchRequest" size="xl">
    <template #modal-title>
      {{ $t('components.patch_request_form.title') }}
    </template>
    <b-alert :show="error_message !== null" variant="danger" dismissible>
      {{ error_message }}
    </b-alert>
    <b-list-group>
      <b-list-group-item v-for="action in $store.state.patch_request_actions" :key="JSON.stringify(action)">
        <b-button-group class="float-right">
          <b-button variant="outline-primary" @click="editActionModal(action)">
            <netvs-icon icon="edit"/>
          </b-button>
          <b-button variant="outline-danger" @click="deleteAction($store.state.patch_request_actions.indexOf(action))">
            <netvs-icon icon="delete"/>
          </b-button>
        </b-button-group>
        {{ action.building.name ? `${action.building.number} (${action.building.name})` : `${action.building.number}` }}
        [{{ action.building.site_fq_name }}]
        <netvs-icon class="mx-1" icon="navigate"/>
        {{ action.room?.name ? `${action.room.number} (${action.room.name})` : action.room.number }} <br>
        {{ $t('components.patch_request_form.actions.'+ action.action) }}:
        <template v-if="action.bcd?.name">
          <b-link target="_blank" :to="'/dnsvs/bcds/' + action.bcd.name">{{ action.bcd.name }}</b-link>
          <netvs-icon class="mx-1" icon="navigate"/>
        </template>
        {{ action.module?.type ? `${action.module.fq_name} (${action.module.type})` : action.module.fq_name }}
        <template v-if="action.p_port">
          {{ $t('components.patch_request_form.p_port') }}: {{ action.p_port?.name }}
        </template>
        <template v-if="action.comment">
          <br>
          "{{ action.comment }}"
        </template>
      </b-list-group-item>
      <!-- add button -->
      <b-list-group-item button variant="success" @click="addActionModal" class="text-center">
        <netvs-icon icon="create"/>
        {{ $t('components.patch_request_form.add_action') }} <!-- open PatchRequestAction modal with return value -->
      </b-list-group-item>
    </b-list-group>
    <b-form-group :label="$t('components.patch_request_form.reply_to') + ':'" class="mt-2" label-for="reply-to-input">
      <b-form-input
        :placeholder="$t('components.patch_request_form.optional_reply_to')"
        v-model="reply_to"
        rows="5"
        class="mb-3" id="reply-to-input"></b-form-input>
    </b-form-group>
    <template #modal-footer="{ ok, cancel }">
      <b-button variant="secondary" @click="cancel()">
        {{ $t('components.patch_request_form.cancel') }}
      </b-button>
      <b-button variant="primary" :disabled="$store.state.patch_request_actions.length < 1" @click="ok()">
        {{ $t('components.patch_request_form.send') }}
        <b-spinner small v-show="sending"/>
        <netvs-icon v-show="status_icon !== null" :icon="status_icon"></netvs-icon>
      </b-button>
    </template>
    <PatchActionForm @ok="handleAction"/>
  </b-modal>
</template>

<script>
import PatchActionForm from '@/components/PatchActionForm'
import PatchRequestService from '@/api-services/patch_request.service'

export default {
  name: 'PatchRequestForm',
  components: {PatchActionForm},
  data() {
    return {
      sending: false,
      status_icon: null,
      error_message: null,
      reply_to: null
    }
  },
  mounted() {
    if (this.$store.state.patch_request_edit_action) {
      this.$bvModal.show('patch-request-form')
      this.$bvModal.show('patch-action-form')
    }
  },
  methods: {
    addActionModal() {
      this.$store.commit('setPatchRequestEditAction', { action: null, index: -1 })
      this.$bvModal.show('patch-action-form')
    },
    editActionModal(action) {
      this.$store.commit('setPatchRequestEditAction', {
        action: action,
        index: this.$store.state.patch_request_actions.indexOf(action)
      })
      this.$bvModal.show('patch-action-form')
    },
    addAction(action) {
      this.$store.commit('addPatchRequestAction', action)
      this.$store.commit('setPatchRequestEditAction', { action: null, index: -1 })
    },
    deleteAction(index) {
      this.$store.commit('removePatchRequestAction', index)
    },
    clearActions() {
      this.$store.commit('clearPatchRequestActions')
    },
    sendPatchRequest(e) {
      e.preventDefault()
      this.status_icon = null
      this.sending = true
      this.error_message = null
      PatchRequestService.send_patch_request(this.$store.state, this.$store.state.patch_request_actions, this.reply_to).then(response => {
        this.sending = false
        this.status_icon = 'request_sent'
        this.clearActions()
        // close modal after 1 second
        setTimeout(() => {
          this.$bvModal.hide('patch-request-form')
        }, 1000)
      }).catch(error => {
        window.console.log(error)
        this.sending = false
        this.status_icon = 'transaction_error'
        if (error.response.status >= 400 && error.response.status < 500) {
          this.error_message = error.response.data.detail
        }
      })
    },
    handleAction(result) {
      if (this.$store.state.patch_request_edit_action_index > -1) {
        this.deleteAction(this.$store.state.patch_request_edit_action_index)
        this.addAction(this.$store.state.patch_request_edit_action)
      } else {
        this.addAction(this.$store.state.patch_request_edit_action)
      }
    }
  }
}
</script>

<style scoped>

</style>
